//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * 2024-07-09 John Yee
 * with thanks to stackoverflow for inspiration...
 * Responsive design - A Read More button only on smaller screens
 * https://stackoverflow.com/questions/15959297/responsive-design-a-read-more-button-only-on-smaller-screens
 */

import cookieConsent from '~/mixins/cookieConsent.js'

export default {
  mixins: [cookieConsent],
  computed: {
    shortCookieText () {
      /**
       * 2024-07-09 John Yee 
       * dict.O30 holds the Cookie Banner description.  The description consumes several lines on
       * a mobilephone, which compresses the landing page main body.
       * 
       * I remove "Cookies:" from the text and keep only a few more words to limit the text to one line.
      */
      let preamble = ''
      try{
        preamble = "<b>"+this.dict.O30.split(" ").slice(2,7).join(" ")+"</b>"
      } catch(error) {
        console.log(error)
      }
      return preamble+"..."
    },
  },
  methods: {
    changeClass () {
      let fullContent = document.getElementById("full-content")
      fullContent.classList.toggle("show-full-content")

      let shortContent = document.getElementById("short-content")
      shortContent.classList.toggle("show-short-content")

      if (shortContent.classList.contains("show-short-content")) {
        shortContent.classList.remove("hidden")
      } else {
        shortContent.classList.add("hidden")
      }

      let readButton  = document.getElementById("read-button")
      if (fullContent.classList.contains("show-full-content")) {
        readButton.innerText = this.dict.Q18 /** Read More */
      } else {
        readButton.innerText = this.dict.Q19 /** Read Less */
      }
    }
  },
}
